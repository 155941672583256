(function() {
  const btn = document.querySelector('#btn-toggle-menu');
  const menu = document.querySelector('#menu');

  btn.addEventListener('click', function(evt) {
    evt.preventDefault();

    menu.classList.toggle('open', true);
    menu.addEventListener('click', cleanup);
    document.addEventListener('mousedown', cleanup);
    document.addEventListener('touchstart', cleanup);

    function cleanup(evt) {
      if (!evt.target || (evt.type === 'click' ? !evt.target.closest('a, button') : (evt.target !== menu && menu.contains(evt.target)))) {
        return;
      }

      menu.classList.toggle('open', false);
      menu.removeEventListener('click', cleanup);
      document.removeEventListener('mousedown', cleanup);
      document.removeEventListener('touchstart', cleanup);
    }
  });
})();
